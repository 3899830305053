import React, { useState, useEffect } from "react";
import CategoryDropdown from "../Layouts/CategoryDropdown";
import CompanyDropdown from "../Layouts/CompanyDropdown";
import { Delete, Edit } from "@mui/icons-material";
import {
  IconButton,
  Modal,
  Button,
  Box,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Popup from "../Layouts/Popup/Popup";
import axios from "axios";
import CommonDelete from "../CommonActions/CommonDelete";
import { useDispatch, useSelector } from "react-redux";
import { AllapprovalCompanysDetails } from "../../redux/action/Company&User/ApprovalCompanys";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import { GetTokenFunc } from "../../utils/GetToken";
import { useGetcreditrequests } from "./Hooks";
import GetBalanceComp from "./GetComapnyBalance";
import LastPurchaseDate from "./LastPurchaseDate";
import LastSellDate from "./LastSellDate";

export let Category = "";
export let CompanyStatus = "";
export let Membership = "";
export let Page = "";
export let SelectedCompany = "";
export let DateFilter = "";

const CompanyListingApprovals = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [id, setId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [activeTab, setActiveTab] = useState(2);
  const [content, setContent] = useState("");
  const [companies, setCompanies] = useState();
  const [companystatus, setCompanystatus] = useState("");
  const [membership, setMembership] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [AllCompanyName, setAllCompanyName] = useState();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [date, setDate] = useState("");
  const [totalProducts, setTotalProducts] = useState([]);
  const [totalMembers, setTotalMembers] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const {
    data: requestData,
    isLoading: ApprovalDataLoading,
    isError: ApprovalDataError,
    refetch: ApprovalDataRefetch,
  } = useGetcreditrequests();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { AllCompany, loading } = useSelector((state) => state.AllCompany);
  useEffect(() => {
    if (AllCompany && AllCompany?.finalCount !== undefined) {
      setInitialPaginationCount(AllCompany?.finalCount);
    }
  }, [AllCompany?.finalCount]);

  Category = category;
  CompanyStatus = companystatus;
  Membership = membership;
  Page = currentPage;
  SelectedCompany = selectedCompany;
  DateFilter = date;

  const DeleteCompany = async () => {
    await axios
      .post(
        `/api/v1/admin/DeleteCompanyById/${id}`,
        {},
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    dispatch(
      AllapprovalCompanysDetails(
        category,
        companystatus,
        membership,
        currentPage
      )
    );
  }, [
    dispatch,
    category,
    companystatus,
    membership,
    selectedCompany,
    date,
    currentPage,
  ]);

  const filterByCompany = async () => {
    await axios
      .get(`product/get_allproducts_bycompany`, { withCredentials: true })
      .then((res) => {
        setAllCompanyName(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    filterByCompany();
  }, []);

  const handleDelete = () => {
    DeleteCompany();
    setIsDeleteModalOpen(false);
  };

  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditButtonClick = (id) => {
    navigate(`/admindashboard/allproductapprovals/${id}`);
  };

  const handleCompanyChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCompany(selectedValue);
  };

  const GetAllTotalProductsCount = async () => {
    await axios
      .get(`/api/v1/admin/GetAllTotalProductsCount`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setTotalProducts(res.data);
      })
      .catch((err) => {});
  };

  const GetAllTotalUserCount = async () => {
    await axios
      .get(`/api/v1/admin/GetAllTotalUserCount`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setTotalMembers(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    GetAllTotalProductsCount();
    GetAllTotalUserCount();
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "600px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box className="page-header-dropwon-section">
        <Box
          className="marketplace-filters"
          sx={{
            width: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={TypoStyle}>Select Category</Typography>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">All</option>
              <option value="63e38bddcc4c02b8a0c94b8a">Others</option>
              <option value="63e38b9ccc4c02b8a0c94b6f">Lifestyle</option>
              <option value="63e38bb9cc4c02b8a0c94b7b">FMCG</option>
              <option value="63e38b91cc4c02b8a0c94b69">Media</option>
              <option value="63e38ba3cc4c02b8a0c94b72">Hotel</option>
              <option value="63e38bbfcc4c02b8a0c94b7e">Airlines Tickets</option>
              <option value="63e38bc6cc4c02b8a0c94b81">Office Supply</option>
              <option value="63e38bb3cc4c02b8a0c94b78">Mobility</option>
              <option value="63e38bcecc4c02b8a0c94b84">Electronics</option>
              <option value="63e38b96cc4c02b8a0c94b6c">Textile</option>
              <option value="64218b189fe1b6ae750c11bd">
                Entertainment & Events
              </option>
              <option value="63e38bd5cc4c02b8a0c94b87">QSR</option>
            </select>
          </Box>
        </Box>
        <Box className="marketplace-filters">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={TypoStyle}>Select Member</Typography>
            <select onChange={handleCompanyChange} value={selectedCompany}>
              <option value="">All</option>
              {AllCompanyName?.map((name, index) => (
                <option key={index} value={name?.companyName}>
                  {name?.companyName}
                </option>
              ))}
            </select>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Box
        className="marketplace-filters"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <label>Status</label>
          <select
            value={companystatus}
            onChange={(e) => setCompanystatus(e.target.value)}
          >
            <option value="">All</option>
            <option value="EMAIL">Email Verification</option>
            <option value="GST">GST Details</option>
            <option value="BANK_DETAILS">Bank Details</option>
            <option value="TNC">T&C</option>
            <option value="PAYMENT">Membership Plans</option>
            <option value="UNDER_REVIEW">Under Review</option>
            <option value="ONBOARDING_COMPLETE">Onboarding Complete</option>
          </select>
        </Box>

        <Box>
          <label>Membership</label>
          <select
            value={membership}
            onChange={(e) => setMembership(e.target.value)}
          >
            <option value="">All</option>
            <option value="advance">Advance Plan</option>
            <option value="basic">Standard Plan</option>
          </select>
        </Box>

        <Box>
          <label>Days</label>
          <select value={date} onChange={(e) => setDate(e.target.value)}>
            <option value="">All</option>
            <option value="7">7 Days</option>
            <option value="15">15 Days</option>
            <option value="30">1 Month</option>
            <option value="90">3 Month</option>
            <option value="180">6 Month</option>
            <option value="360">1 Year</option>
            <option value="1080">3 Year</option>
            <option value="1800">5 Year</option>
          </select>
        </Box>
      </Box>
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        <Box className="products-filters-row">
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              {AllCompany instanceof Object ? (
                <Typography sx={textBudge}>
                  Total Members : {AllCompany?.allCompanySCount}
                </Typography>
              ) : (
                <Typography sx={textBudge}>Total Members: 0</Typography>
              )}
            </Typography>
          </Box>
        </Box>
        <div>
          {AllCompany?.Companys?.length <= 0 ? (
            <Box sx={setBox}>
              <img
                src={recyclebin}
                alt="recycle"
                style={{ height: "auto", width: "23%" }}
              />
              <Typography sx={empty}>Data is empty</Typography>
            </Box>
          ) : (
            <table className="common-table">
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 5,
                    width: "100%",
                  }}
                >
                  <PageLoader />
                </Box>
              ) : (
                <>
                  <thead>
                    <tr>
                      <td width={"4%"} align="left">
                        No .
                      </td>
                      <td width={"18%"} align="left">
                        Member Name
                      </td>
                      <td width={"10%"}>Status</td>
                      <td width={"10%"}>Total Products</td>
                      <td width={"5%"}>Members</td>
                      <td width={"10%"}>Date</td>
                      <td width={"5%"}>Membership</td>
                      <td width={"10%"}> Purchase</td>
                      <td width={"9%"}>Last Purchase</td>
                      <td width={"10%"}>Sale</td>
                      <td width={"9%"}>Last Sale</td>
                      <td width={"4%"}>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {AllCompany?.Companys?.map((x, i) => {
                      return (
                        <tr>
                          <td className="font-10-600">
                            <span>{i + 1}</span>
                          </td>
                          <td align="left" className="product-title-colum">
                            <img
                              src={x?.CompanyAvatar?.url}
                              alt=""
                              style={{ height: "30px", width: "30px" }}
                            />
                            <Box className="product-title-box">
                              <label>{x?.companyName}</label>
                              <span>Company ID : {x?._id} </span>
                            </Box>
                          </td>
                          {x?.companyOnboardingStatus?.length > 0 ? (
                            <td className="blueText">
                              <label>{x?.companyOnboardingStatus}</label>
                              <span>&nbsp;</span>
                            </td>
                          ) : (
                            <td className="blueText">
                              <label>Email Verification</label>
                              <span>&nbsp;</span>
                            </td>
                          )}
                          <td className="blueText">
                            <label>
                              {totalProducts
                                .filter(
                                  (product) => product?.company === x?._id
                                )
                                .reduce(
                                  (acc, product) =>
                                    acc + (product.productCount || 0),
                                  0
                                )}
                            </label>
                            <span>&nbsp;</span>
                          </td>

                          <td className="blueText">
                            <label>
                              {totalMembers
                                .filter((member) => member?.company === x?._id)
                                .reduce(
                                  (acc, member) => acc + (member.Users || 0),
                                  0
                                )}
                            </label>
                            <span style={{ width: "10px" }}>&nbsp;</span>
                          </td>
                          <td className="date">
                            {" "}
                            {new Date(x?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                timeZone: "UTC",
                              }
                            )}
                          </td>

                          <td className="font-12-400">
                            {x?.companyOnboardingStatus ===
                            "ONBOARDING_COMPLETE" ? (
                              x?.memberships ? (
                                <>
                                  {x?.memberships[x?.memberships?.length - 1]
                                    ?.Membership === "basic"
                                    ? "Standard"
                                    : x?.memberships[x?.memberships?.length - 1]
                                        ?.Membership === "advance"
                                    ? "Advance"
                                    : x?.ChoosedPlan}{" "}
                                </>
                              ) : null
                            ) : (
                              "--"
                            )}
                          </td>

                          <td className="blueText">
                            <label>
                              <GetBalanceComp id={x?._id} Purchase={true} />
                            </label>
                          </td>

                          <td className="date">
                            <LastPurchaseDate companyId={x?._id} />
                          </td>

                          <td className="blueText">
                            <label>
                              <GetBalanceComp id={x?._id} Sell={true} />
                            </label>
                          </td>

                          <td className="date">
                            <LastSellDate companyId={x?._id} />
                          </td>

                          <td>
                            <Box sx={{ display: "flex" }}>
                              <IconButton
                                aria-label="edit"
                                style={iconStyle}
                                onClick={() => {
                                  handleEditButtonClick(x._id);
                                  setId(x._id);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Box>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              )}
            </table>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              mx: "auto",
            }}
            mt={2}
          >
            <Stack>
              <StyledPagination
                size="medium"
                count={initialPaginationCount}
                color="primary"
                showFirstButton
                showLastButton
                page={currentPage}
                onChange={handlePageChange}
                strokeWidth={currentPage}
              />
            </Stack>
          </Box>
        </div>
      </Box>
      <Popup
        openPopup={isOpen}
        popupTitle="Edit : Product Details"
        popupContent={content}
        handleClose={() => {
          setIsOpen(false);
        }}
      />
      <CommonDelete
        open={isDeleteModalOpen}
        onClose={handleClose}
        onDelete={handleDelete}
        text="Are you sure you want to delete this Company?"
        ButtonLabel="Delete"
        cancelButtonLabel="Cancel"
      />
    </Paper>
  );
};

export default CompanyListingApprovals;
const iconStyle = {
  color: "rgba(68, 95, 210, 1)",
};

const DeleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const ModalHeaderStyle = {
  color: "#6B7A99",
  fontFamily: "Inter",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  my: 2,
};

const ButtonBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "flex-end",
  gap: 1,
  width: "100%",
  bottom: 0,
  height: "100%",
  mt: 2,
};
const ButtonStyle = {
  fontFamily: "poppins",
  fontSize: 10,
  fontWeight: 500,
  border: "0.5px solid rgba(208, 213, 221, 1)",
  width: "140px",
  textTransform: "none",
  borderRadius: 1.5,
};

const TypoStyle = {
  width: "110px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#6B7A99",
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  // lineHeight: 20,
};
