import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import TabPanel from "@mui/lab/TabPanel";
import {
  Accordion,
  Box,
  Button,
  Dialog,
  Grid,
  Modal,
  Paper,
  Tabs,
  Typography,
} from "@mui/material";
import TabScrollButton from "@mui/material/TabScrollButton";
import { Link } from "react-router-dom";
import { GetAllProduct } from "../../redux/action/MarketPlace/Product-Filter";
import { AllCompanysDetails } from "../../redux/action/Company&User/AllCompanys";
import { AllapprovalCompanysDetails } from "../../redux/action/Company&User/ApprovalCompanys.js";
import { useDispatch, useSelector } from "react-redux";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import barterLogo from "../../assets/BXI_LOGO.png";
import NotifyIcon from "../../assets/Notification.png";
import ProfileVector from "../../assets/ProfileImage/ProfileVector.svg";
import Username from "../../assets/ProfileImage/username.svg";
import Gmail from "../../assets/ProfileImage/gmail.svg";
import Lastlogin from "../../assets/ProfileImage/lastlogin.svg";
import Role from "../../assets/ProfileImage/role.svg";
import Access from "../../assets/ProfileImage/access.svg";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Chat from "../../assets/Chat.png";
import { useNavigate } from "react-router-dom";
import CloseIconForNotification from "../../assets1/HeaderIcon/CloseIconForNotification.svg";
import { useGetLoggedInUser } from "./Hooks";
import axios from "axios";
import { allNotification } from "../../redux/action/Notification/getNotifications";
import AvatarGenerator from "../Layouts/AvatarGenerator.jsx";
import { format } from "timeago.js";
import CloseIcon from "../../assets/CloseIcon.svg";

export let search = "";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  // borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  background: "#FAFBFC",
  border: "1px solid #445FD2",
}));

function CustomTabScrollButton(props) {
  return <TabScrollButton {...props} style={{ display: "none" }} />;
}

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "70ch",
    },
  },
}));

const pages = ["Home", "About", "Marketplace", "Contact Us"];

const AdminHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [ProfileModal, setProfileModal] = useState(false);
  const [value, setValue] = React.useState("1");
  const [marketplacesearch, setMarketplaceSearch] = useState("");
  const [notification, setNotification] = useState([]);
  const [notificationDot, setNotificationDot] = useState(true);
  const [messagenotificationDot, setMessageNotificationDot] = useState(false);
  const [totalCountBXIArray, SetTotalCountBXIArray] = useState([]);
  const [totalCountBuyerArray, setTotalCountBuyerArray] = useState([]);
  const [totalCountSellerArray, setTotalCountSellerArray] = useState([]);
  const [incrementAll, setIncrementAll] = useState(false);
  const [UpdateNotiticationStatus, setUpdateNotiticationStatus] =
    useState(false);
  const [unReadCountOfAll, setUnReadCountOfAll] = useState(null);

  search = marketplacesearch;
  useEffect(() => {
    if (window.location.pathname.includes("marketplace")) {
      dispatch(GetAllProduct());
    } else if (window.location.pathname.includes("companies")) {
      dispatch(AllCompanysDetails());
    } else if (window.location.pathname.includes("approvallisting")) {
      dispatch(AllapprovalCompanysDetails());
    }
  }, [dispatch, search]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleProfileModalopen = () => {
    setProfileModal(true);
  };
  const handleProfileModalclose = () => {
    setProfileModal(false);
  };
  const {
    data: loggedInUser,
    isLoading,
    isError,
    isSuccess,
  } = useGetLoggedInUser();

  // console.log("loggedInUser", loggedInUser);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { getNotification } = useSelector((state) => state.allNotifiactions);

  const adminUser = "650ac9c7b29820230639ab99";
  const companyName = "BXI Pvt Ltd";

  useEffect(() => {
    if (adminUser) {
      dispatch(allNotification(adminUser));
    }
  }, [adminUser]);

  const UpdateNotifications = async (data) => {
    let status = null;

    if (data?.adminDeleted === false) {
      status = true;
    } else if (data?.adminDeleted === true) {
      status = false;
    }

    await axios
      .put(
        `AdminCompanyDetails/AdminDeleteNotification/${data._id}`,
        { delete: status },
        { withCredentials: true }
      )
      .then(() => {
        dispatch(allNotification(adminUser));
      })
      .catch((err) => {});
  };

  // useEffect(() => {
  //   socket.on("notificationS", (data) => {
  //     if (data !== null) {
  //       // dispatch(
  //       //   notifications(data.sellerId, data.buyerId, data.message, data.type)
  //       // );
  //       setNotification((prevNotification) => [data, ...prevNotification]);
  //       setNotificationDot(true);
  //       setIncrementAll(true);
  //       // const audio = new Audio(notificationbell);
  //       // audio.play();
  //     }
  //   });

  //   socket.on("MessagesNotification", (data) => {
  //     if (data !== null) {
  //       // dispatch(
  //       //   notifications(data.senderId, data.ReceiverId, data.message, data.type)
  //       // );
  //       setNotification((prevNotification) => [data, ...prevNotification]);
  //       setMessageNotificationDot(true);
  //       setNotificationDot(true);
  //       setIncrementAll(true);
  //       // const audio = new Audio(notificationbell);
  //       // audio.play();
  //     }
  //   });

  //   socket.on("QueryNotificationR", (data) => {
  //     if (data !== null) {
  //       // dispatch(
  //       //   notifications(data.sellerId, data.buyerId, data.message, data.type)
  //       // );
  //       setNotification((prevNotification) => [data, ...prevNotification]);
  //       setNotificationDot(true);
  //       setIncrementAll(true);
  //       // const audio = new Audio(notificationbell);
  //       // audio.play();
  //     }
  //   });

  //   socket.on("notificationS", (data) => {
  //     if (data !== null) {
  //       // dispatch(
  //       //   notifications(data.sellerId, data.buyerId, data.message, data.type)
  //       // );
  //       setNotification((prevNotification) => [data, ...prevNotification]);
  //       setNotificationDot(true);
  //       setIncrementAll(true);

  //       // const audio = new Audio(notificationbell);
  //       // audio.play();
  //     }
  //   });

  //   socket.on("OrderTracking", (data) => {
  //     if (data !== null) {
  //       setNotification((prevNotification) => [data, ...prevNotification]);
  //       setNotificationDot(true);
  //       setIncrementAll(true);

  //       // const audio = new Audio(notificationbell);
  //       // audio.play();
  //     }
  //   });

  // }, []);

  const handleClickRedirect = (message) => {
    if (message.includes("account")) {
      navigate("/admindashboard/companies");
    } else if (message.includes("14")) {
      navigate("/admindashboard/marketplace");
    } else if (message.includes("membership")) {
      navigate("/admindashboard/memberdirectory");
    } else if (message.includes("onboarding")) {
      navigate("/admindashboard/companies");
    } else if (message.includes("Credit")) {
      navigate("/admindashboard/creditline");
    } else if (message.includes("credit")) {
      navigate("/admindashboard/creditline");
    } else if (message.includes("uploaded")) {
      navigate("/admindashboard/marketplace");
    } else if (message.includes("delisted")) {
      navigate("/admindashboard/marketplace");
    } else if (message.includes("15")) {
      navigate("/admindashboard/genieneactiveinactive");
    } else if (message.includes("profile")) {
      navigate("/admindashboard/companies");
    }
    handleClose();
  };

  useEffect(() => {
    if (getNotification !== null) {
      if (getNotification) {
        const AllCount = getNotification.filter((item) => item.read === false);
        setUnReadCountOfAll(AllCount.length);
      }
    }
  }, [getNotification]);

  useEffect(() => {
    // if (window.location.pathname.includes("/admindashboard/marketplace/")) {
    setMarketplaceSearch("");
    // }
  }, [window.location.pathname]);

  return (
    <Box className="main-box">
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#FDFDFD",
          color: "#6B7A99",
          boxShadow: 0,
          borderBottom: "2px solid #F0F0F0",
        }}
      >
        <Container maxWidth="fluid">
          <Toolbar disableGutters>
            <Box sx={{ width: "100px" }}>
              <img
                src={barterLogo}
                alt="img"
                style={{ height: "auto", width: "50px" }}
              />
            </Box>
            <Typography
              sx={{
                mr: 5,
                fontFamily: "Poppins",
                fontWeight: 700,
                color: "#6B7A99",
                textDecoration: "none",
                fontSize: "16px",
              }}
            >
              Admin Panel
            </Typography>
            <Box
              sx={{
                width: "40%",
                mx: "auto",
              }}
            >
              {(window.location.pathname.includes(
                "/admindashboard/marketplace"
              ) &&
                !window.location.pathname.includes(
                  "/admindashboard/marketplace/"
                )) ||
              window.location.pathname.includes("companies") ||
              window.location.pathname.includes("approvallisting") ? (
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="search"
                    inputProps={{ "aria-label": "refresh" }}
                    value={marketplacesearch}
                    onChange={(e) => {
                      setMarketplaceSearch(e.target.value);
                      // console.log("refresh", e.target.value);
                    }}
                  />
                </Search>
              ) : null}
            </Box>

            {/*   <Stack direction="row" spacing={1}>
                <Avatar>H</Avatar>
                <Typography
                sx={barter}
              >
              Barter Exchange 
              </Typography>
          </Stack> */}
            {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (
                    <Button
                        key={page}
                        sx={{ my: 2, color: '#6B7A99',fontSize: '10px', display: 'block',fontFamily: 'Poppins', fontWeight: 400,}}
                    >
                        {page}
                    </Button>
                    ))}
                </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "20px",
                ml: "auto",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={handleProfileModalopen}
                >
                  {loggedInUser?.name}
                </Typography>
                <Modal
                  open={ProfileModal}
                  onClose={handleProfileModalclose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Box
                      component="img"
                      src={ProfileVector}
                      sx={{
                        position: "absolute",
                        bottom: "0%",
                        right: "0%",
                        width: "200px",
                        height: "200px",
                        zIndex: "-1",
                      }}
                    />
                    <Typography sx={HeaderMainTextStyle}>
                      {" "}
                      Profile Details
                      <Box
                        component="img"
                        src={CloseIcon}
                        sx={{
                          width: "25px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={handleProfileModalclose}
                      />
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography sx={HeaderTextStyle}>
                        <Box
                          component="img"
                          src={Username}
                          sx={{
                            width: "25px",
                            height: "auto",
                          }}
                        />
                        <Box>
                          Name
                          <br />
                          <span style={HeaderSubTextStyle}>
                            {loggedInUser?.name}
                          </span>
                        </Box>
                      </Typography>
                      <Typography sx={HeaderTextStyle}>
                        <Box
                          component="img"
                          src={Gmail}
                          sx={{
                            width: "25px",
                            height: "auto",
                          }}
                        />
                        <Box>
                          Email
                          <br />
                          <span style={HeaderSubTextStyle}>
                            {loggedInUser?.email}
                          </span>
                        </Box>
                      </Typography>
                      <Typography sx={HeaderTextStyle}>
                        <Box
                          component="img"
                          src={Role}
                          sx={{
                            width: "25px",
                            height: "auto",
                          }}
                        />
                        <Box>
                          Role
                          <br />
                          <span style={HeaderSubTextStyle}>
                            {loggedInUser?.role}
                          </span>
                        </Box>
                      </Typography>
                      <Typography sx={HeaderTextStyle}>
                        <Box
                          component="img"
                          src={Lastlogin}
                          sx={{
                            width: "25px",
                            height: "auto",
                          }}
                        />
                        <Box>
                          Last-Login
                          <br />
                          <span style={HeaderSubTextStyle}>
                            {new Date(
                              loggedInUser?.lastLogin
                            )?.toLocaleString()}
                          </span>
                        </Box>
                      </Typography>
                      {loggedInUser?.acess <= 0 ? null : (
                        <Typography sx={HeaderTextStyle}>
                          <Box
                            component="img"
                            src={Access}
                            sx={{
                              width: "25px",
                              height: "auto",
                            }}
                          />
                          <Box>
                            Access
                            <br />
                            {[...new Set(loggedInUser?.acess)].map((x, i) => {
                              return (
                                <span style={HeaderSubTextStyle}>{x}, </span>
                              );
                            })}
                          </Box>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Modal>
              </Box>
              <Box
                sx={ButtonStyle}
                onClick={() => {
                  navigate("/admindashboard/chat");
                }}
              >
                <img
                  src={Chat}
                  alt="CartIcon"
                  width="auto"
                  height={"18px"}
                  style={{
                    cursor: "pointer",
                    padding: "0px",
                    // background: '#000',
                  }}
                />
              </Box>
              <Box
                sx={ButtonStyle}
                onClick={() => {
                  handleOpen();
                }}
              >
                {notificationDot === true ? (
                  <Box
                    sx={{
                      bgcolor: "red",
                      MinHeight: "13px",
                      height: "auto",
                      MinWidth: "16px",
                      width: "auto",
                      borderRadius: "20px",
                      position: "absolute",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "10px",
                      px: 0.5,
                      top: {
                        xl: "7px",
                        lg: "7px",
                        md: "7px",
                        sm: "2px",
                        xs: "2px",
                      },
                      right: {
                        xl: "7px",
                        lg: "7px",
                        md: "7px",
                        sm: "2px",
                        xs: "2px",
                      },
                      zIndex: 1,
                      color: "white",
                    }}
                  >
                    {incrementAll === true
                      ? unReadCountOfAll + 1
                      : unReadCountOfAll}
                  </Box>
                ) : null}

                <img
                  src={NotifyIcon}
                  alt="CartIcon"
                  width="auto"
                  height={"18px"}
                  style={{
                    cursor: "pointer",
                    padding: "0px",
                    // background: '#000',
                  }}
                />
              </Box>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 18,
                        color: "#6B7A99",
                      }}
                    >
                      Notifications
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      {/*    <Box
                        component="img"
                        src={FullScreenIcon}
                        sx={HeaderIcons}
                    ></Box> */}
                      <Box
                        component="img"
                        src={CloseIconForNotification}
                        onClick={() => {
                          handleClose();
                        }}
                        sx={{ ...HeaderIcons }}
                      ></Box>
                    </Box>
                  </Box>
                  <Box>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant={
                          window.innerWidth < 1400 ? "scrollable" : "standard"
                        }
                        scrollButtons={{
                          start: <CustomTabScrollButton />,
                          end: <CustomTabScrollButton />,
                        }}
                        sx={{
                          width: "auto",
                          borderBottom: "1px solid #E5E5E5",
                          wordWrap: "break-word",
                          position: "sticky",
                        }}
                      >
                        <Tab label="All" value="1" sx={SubTabStyle} />
                      </Tabs>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      overflow: "auto",
                      "::-webkit-scrollbar": {
                        display: "flex",
                      },
                      "::-webkit-scrollbar-thumb": {
                        dynamic: "#8d8e90",
                        minHeight: "10px",
                        borderRadius: "8px",
                      },
                      "::-webkit-scrollbar-thumb:vertical": {
                        maxHeight: "30px",
                      },
                      maxHeight: "300px",
                      height: "290px",
                      p: 1,
                    }}
                  >
                    <TabContext value={value}>
                      <TabPanel
                        value={"1"}
                        sx={{
                          padding: "0px",
                          boxShadow: 0,
                          "::before": {
                            height: "0px",
                            left: "0px",
                          },
                        }}
                      >
                        {getNotification?.map((notification, idx) => {
                          return (
                            <Accordion
                              key={idx}
                              sx={{
                                mt: 1,
                                boxshadow: "0px 0px 0px 0px",
                                border: "none",
                                boxShadow: 0,
                                "::before": {
                                  height: "0px",
                                  left: "0px",
                                },
                              }}
                            >
                              <Box
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                  mt: 1,
                                  position: "relative",
                                  "::before": {
                                    height: "0px",
                                    left: "0px",
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    display:
                                      notification.read === true
                                        ? "none"
                                        : "flex",
                                    bgcolor: "red",
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "20px",
                                    position: "absolute",
                                    zIndex: 1,
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <AvatarGenerator companyname={companyName} />

                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={NotificationMessage}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleClickRedirect(
                                            notification.message
                                          );
                                          // sendEvents("Click on redirect");
                                        }}
                                      >
                                        {notification.message}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "Outfit",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "10px",
                                          color: "#6C6C6C",
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                        }}
                                      >
                                        {format(notification?.createdAt)}
                                      </Typography>

                                      <Box
                                        sx={{
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: 10,
                                          color: "#445FD2",
                                          textAlign: "right",
                                          cursor: "pointer",
                                          display: "flex",
                                          flexDirection: "flex-end",
                                          ml: "auto",
                                        }}
                                        onClick={() => {
                                          UpdateNotifications(notification);
                                          setUpdateNotiticationStatus(
                                            !UpdateNotiticationStatus
                                          );

                                          // sendEvents(
                                          //   "Click on notification"
                                          // );
                                        }}
                                      >
                                        {notification.adminDeleted === false
                                          ? "delete"
                                          : "restore"}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Accordion>
                          );
                        })}
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
export default AdminHeader;
const ButtonStyle = {
  width: "45px",
  height: "45px",
  background: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  boxShadow: "6px 4px 7px rgba(38, 51, 77, 0.03)",
  borderRadius: "100px",
  cursor: "pointer",
  position: "relative",
};

// const style = {
//   position: 'absolute',
//   top: '37%',
//   left: '80%',
//   transform: 'translate(-50%, -50%)',
//   width: '28%',
//   height: '370px',
//   overflow: 'scroll',
//   bgcolor: 'background.paper',
//   border: '0px solid #000',
//   boxShadow: 4,
//   p: '30px',
//   borderRadiusTopRight: '10px',
//   maxWidth: '500px',
//   minWidth: '250px',
//   borderRadius: '20px 20px 0px 0px',
// };

const ExploreButtonStyle = {
  width: "92px",
  height: "44px",
  background: "#FFFFFF",
  borderRadius: "6px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "21px",
  /* identical to box height */
  textAlign: "center",
  color: "#6B7A99",
  textTransform: "none",
};

const ButtonsStyle = {
  width: "45px",
  height: "45px",
  background: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  boxShadow: "0px 2px 5px rgba(38, 51, 77, 0.03)",
  borderRadius: "100px",
  cursor: "pointer",
};

const UnActiveExploreButtonStyle = {
  width: "92px",
  height: "44px",
  background: "transparent",
  borderRadius: "6px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "21px",
  /* identical to box height */
  textAlign: "center",
  color: "#ADB8CC",
  textTransform: "none",
};

const HeaderMainTextStyle = {
  display: "flex",
  justifyContent: "space-between",
  fontFamily: "Poppins",
  textAlign: "left",
  color: "#6B7A99",
  fontSize: "16px",
  fontWeight: 500,
  borderBottom: "0.5px solid #D8D8D8",
  p: 1,
};
const HeaderTextStyle = {
  display: "flex",
  gap: "20px",
  fontFamily: "Poppins",
  fontSize: "15.5px",
  fontWeight: 600,
  color: "#6B7A99",
  p: 1,
};
const HeaderSubTextStyle = {
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
  textAlign: "left",
  color: "#858585",
  p: 1,
};

const UserNameTextStyle = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
};

const style = {
  position: "absolute",
  top: "37%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  width: "32%",
  height: "400px",
  overflow: "scroll",
  bgcolor: "#ffffff",
  boxShadow: 2,
  px: "30px",
  py: "20px",
  border: "none",
  borderRadius: "20px",
  maxWidth: "500px",
  minWidth: "250px",
};

const HeaderIcons = {
  width: "20px",
  height: "20px",
  cursor: "pointer",
};

const SubTabStyle = {
  width: "auto",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "15px",
  color: "#000000",
};

const NotificationMessage = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};

const NotificationTime = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  color: "#6C6C6C",
};

const notificationcount = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: "22px",
  height: "20px",
  background: "#F8E8EA",
  borderRadius: "2px",
  mt: 1.2,
  p: "3px",
  ml: "-3%",
};

const barter = {
  fontFamily: "Poppins",
  fontWeight: 700,
  color: "#6B7A99",
  textDecoration: "none",
  fontSize: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
