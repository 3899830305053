import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import PageLoader from "../components/CommonActions/PageLoader";
import { toast } from "react-toastify";
import EnachTransactionStatus from "./EnachTransactionStatus";

const ApprovedEnachList = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const [buttonLoading, setButtonLoading] = useState(false);

  const [storeResponse, setStoreResponse] = useState();
  const fetchEnachDebitStatus = async (OrderId) => {
    try {
      await axios
        .post(`enach_mandate/check_enach_recurring_transaction_api_status`, {
          OrderId: OrderId,
        })
        .then((res) => {
          console.log(res);
          setStoreResponse(res?.data?.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const checkMandateStatus = async (mandateId) => {
    try {
      await axios
        .post(`enach_mandate/check_mandate_status`, {
          mandateId: mandateId,
        })
        .then((res) => {
          console.log("mandate response--->", res);
        });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("storeResponse", storeResponse);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const fetchAllMemberships = async () => {
    try {
      const response = await axios.get(
        `admin_orders/get_escrow_trustee_approved_orders?page=${currentPage}`
      );
      setData(response?.data);
      setInitialPaginationCount(response.data?.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLoading(false);
    }
  };

  const sendBxiAmountDebitConfirmation = async (id) => {
    try {
      await axios
        .post(`admin_orders/send_bxi_enach_amount_debit_confirmation`, {
          OrderId: id,
        })
        .then((response) => {
          console.log("response", response);
          toast.success("Email sent successfully");
          fetchAllMemberships();
        });
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    fetchAllMemberships();
  }, [currentPage]);

  if (buttonLoading) {
    return <PageLoader />;
  } else {
    return (
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              lineHeight: "32px",
              color: "#000",
              letterSpacing: "-0.01em",
              textAlign: "center",
            }}
          >
            Only Escrow Trustee Approved Order will be Visible here
          </Typography>
        </Box>
        <TableContainer sx={{ mt: "40px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={HeaderTextStyle}>#</TableCell>
                <TableCell sx={HeaderTextStyle}>Company Name</TableCell>
                <TableCell sx={HeaderTextStyle}>Order ID</TableCell>
                <TableCell sx={HeaderTextStyle}>INR Amount</TableCell>
                <TableCell sx={HeaderTextStyle}>Email Sent Date</TableCell>
                <TableCell sx={HeaderTextStyle}>Mandate Status</TableCell>
                <TableCell sx={HeaderTextStyle}>Status</TableCell>
                <TableCell sx={HeaderTextStyle}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((response, idx) => {
                console.log("response", idx + 1, response?.mandateData?.data);
                const continuousIdx = (currentPage - 1) * 10 + idx + 1;
                const INRAmount = (
                  response?.INRDetails?.totalBxiCommission +
                  response?.INRDetails?.bxiCommisionGST
                ).toFixed(2);

                const INRAmountMedia = (
                  response?.INRDetails?.TotalBxiCommission +
                  response?.INRDetails?.BxiCommisionGST
                ).toFixed(2);

                const date = new Date(
                  response?.mandate_mail?.dateAndTime
                ).toLocaleString();
                const mandateDate = new Date(
                  response?.mandate_mail?.dateAndTime
                );
                const now = new Date();
                const timeDifference = now - mandateDate;
                const hoursDifference = timeDifference / (1000 * 60 * 60);

                return (
                  <TableRow>
                    <TableCell>{continuousIdx}</TableCell>
                    <TableCell>
                      {
                        response?.PurchaseOrderData?.SellerDetails
                          ?.SellerCompanyName
                      }
                    </TableCell>
                    <TableCell>{response?.OrderId}</TableCell>
                    <TableCell>
                      {response?.IsMedia ? INRAmountMedia : INRAmount}
                    </TableCell>
                    <TableCell>{date}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          checkMandateStatus(
                            response?.mandateData?.data?.mandateid
                          )
                        }
                      >
                        Check Mandate
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => fetchEnachDebitStatus(response?._id)}
                      >
                        Check Status
                      </Button>
                    </TableCell>
                    <TableCell>
                      {response?.mandateData?.data
                        ?.amount ? null : hoursDifference > 24 ? (
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                          }}
                          onClick={() => {
                            sendBxiAmountDebitConfirmation(response?.OrderId);
                          }}
                        >
                          Debit Amount
                        </Button>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
            mx: "auto",
          }}
          mt={2}
        >
          <Stack>
            <StyledPagination
              size="medium"
              count={initialPaginationCount}
              color="primary"
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={handlePageChange}
              strokeWidth={currentPage}
            />
          </Stack>
        </Box>
      </Box>
    );
  }
};

export default ApprovedEnachList;

const HeaderTextStyle = {
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "16px",
  color: "#445FD2",
  fontFamily: "Poppins",
};
